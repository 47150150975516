const dashboardMenu = [
    {
        id: 1,
        text: `Personal Profile`,
        href: `/personal-profile`
    },
    {
        id: 2,
        text: `Claim Management`,
        href: `/claim-management`
    },
    {
        id: 3,
        text: `Leave Management`,
        href: `/leave-management`
    },
    {
        id: 4,
        text: `Work Remotely`,
        href: `/work-remotely`
    },
    {
        id: 5,
        text: `Meeting Notes`,
        href: `/meeting-notes`
    },
    {
        id: 6,
        text: `My Tasks`,
        href: `/my-tasks`
    },
    {
        id: 7,
        text: `HR Policies`,
        href: `/hr-policies`
    },
    {
        id: 8,
        text: `Culture & Tenets`,
        href: `/culture-and-tenets`
    },
    {
        id: 9,
        text: `Meetings & Tools`,
        href: `/meetings-and-tools`
    },
    {
        id: 10,
        text: `Know the Myelinners`,
        href: `/myelinners`
    },
    {
        id: 11,
        text: `Know the Advisors`,
        href: `/advisors`
    },
    {
        id: 12,
        text: `Portal Feedback`,
        href: `/portal-feedback`
    },

   
    // {
    //     id: 5,
    //     text: `My Benefits`,
    //     href: `/my-benefits`
    // },
    
    // {
    //     id: 6,
    //     text: 'Feedback',
    //     href: '/feedback'
    // }
    
]


export default dashboardMenu;