import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import ViewPersonalProfile from "./ViewPersonalProfile/ViewPersonalProfile";
import ViewTrainingRequirements from "./ViewTrainingRequirements/ViewTrainingRequirements";
import ViewLeaveManagement from "./ViewLeaveManagement/ViewLeaveManagement";
import ViewClaimManagement from "./ViewClaimManagement/ViewClaimManagement";
import ViewMyBenefits from "./ViewMyBenefits/ViewMyBenefits";
import { useAuth } from "../Hooks/useAuth";
import LoginPage from "./LoginPage/LoginPage";
import AdminAddEmployee from "./AdminAddEmployee/AdminAddEmployee";
import AdminEditEmployee from "./AdminEditEMployee/AdminEditEmployee";
import AdminMyeliners from "./AdminMyeliners/AdminMyeliners";
import AdminEmployeeDetails from "./AdminViews/AdminEmployeeDetails";
import AdminExpenseClaims from "./AdminClaims/AdminExpenseClaims";
import AdminLeaveApplications from "./AdminClaims/AdminLeaveApplications";
import ViewFeedback from "./ViewFeedback/ViewFeedback";
import ViewRemoteWork from "./ViewRemoteWork/ViewRemoteWork";
import AdminRemoteWork from "./AdminClaims/AdminRemoteWork";
import ViewHRPolicy from "./ViewHRPolicy/ViewHRPolicy";
import AdminArchivedMyeliners from "./AdminMyeliners/AdminArchivedMyeliners";
import ViewMeetingTools from "./ViewHRPolicy/ViewMeetingTools";
import ViewMeetingMinutes from "./ViewMeetingMinutes/ViewMeetingMinutes";
import ViewMasterExportImport from "./ViewMasterExportImport";
import ViewMyelinners from "./ViewMyelinners/ViewMyelinners";
import ViewKnowAdvisors from "./ViewKnowAdvisors/ViewKnowAdvisors";
import AdminAssignTask from "./AdminAssignTask/AdminAssignTask";
import ViewMyTasks from "./ViewMyTasks/ViewMyTasks";
import ViewPortalFeedback from "./ViewPortalFeedback/ViewPortalFeedback";
import AdminPortalFeedbacks from "./ViewPortalFeedback/AdminPortalFeedbacks";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { myelinersCollectionRef } from "../firebase/fire-app";
import ViewDemoDemo from "./ViewDemoDemo/ViewDemoDemo";
import ViewBlogs from "./ViewBlogs/ViewBlogs";
import AdminEditBlog from "./ViewBlogs/AdminEditBlog";
import ViewLeadershipCulturePrinciple from "./ViewCultureLeadershipPrinciple/ViewLeadershipCulturePrinciple";
import PageLoader from "../Components/Shared/PageLoader/PageLoader";
import AdminJobPortal from "./AdminJobPortal/AdminJobPortal";
import ViewGallery from "./ViewGallery/ViewGallery";
import AdminDocumentManager from "./AdminDocumentManager/AdminDocumentManager";

const Main = () => {
    const { hasAccess, isAdmin, currentUser, myelinners, setMyelinners } =
        useAuth();
    const [empID, setEmpID] = useState(null);
    const [empEmail, setEmpEmail] = useState(null);

    const [registerOn, setRegisterOn] = useState(false);

    const [systemLoading, setSystemLoading] = useState(false);

    const [getMyelinners, loading, error] = useCollectionData(
        myelinersCollectionRef.orderBy("empID", "desc"),
        { idField: "id" }
    );

    useEffect(() => {
        if (getMyelinners && getMyelinners.length > 0) {
            setMyelinners(getMyelinners);
        }
    }, [getMyelinners]);

    const giveAccess = hasAccess;
    useEffect(() => {
        if (currentUser && currentUser.empID && currentUser.email) {
            setEmpEmail(currentUser.email);
            setEmpID(currentUser.empID);
            setSystemLoading(false);
        }
    }, [currentUser]);
    return (
        <>
            <Router>
                {systemLoading ? (
                    <PageLoader />
                ) : (
                    <Routes>
                        {giveAccess && currentUser && empEmail && (
                            <Route
                                path="/personal-profile"
                                element={
                                    <ViewPersonalProfile email={empEmail} />
                                }
                            />
                        )}
                        {giveAccess && currentUser && empID && (
                            <Route
                                path="/claim-management"
                                element={
                                    <ViewClaimManagement empID={empID?.empID} />
                                }
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/add-employee"
                                element={<AdminAddEmployee />}
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/assign-task"
                                element={<AdminAssignTask />}
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/edit-employee-info"
                                element={<AdminEditEmployee />}
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/archive"
                                element={<AdminArchivedMyeliners />}
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/myeliners"
                                element={<AdminMyeliners />}
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/myeliners/:email"
                                element={<AdminEmployeeDetails />}
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/expense-claims"
                                element={<AdminExpenseClaims />}
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/remote-works"
                                element={<AdminRemoteWork />}
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/leave-applications"
                                element={<AdminLeaveApplications />}
                            />
                        )}
                        {giveAccess && currentUser && (
                            <Route
                                path="/leave-management"
                                element={<ViewLeaveManagement />}
                            />
                        )}
                        {giveAccess && currentUser && (
                            <Route
                                path="/work-remotely"
                                element={<ViewRemoteWork />}
                            />
                        )}
                        {giveAccess && currentUser && (
                            <Route
                                path="/hr-policies"
                                element={<ViewHRPolicy />}
                            />
                        )}
                        {giveAccess && currentUser && (
                            <Route
                                path="/culture-and-tenets"
                                element={<ViewLeadershipCulturePrinciple />}
                            />
                        )}
                        {giveAccess && currentUser && (
                            <Route path="/my-tasks" element={<ViewMyTasks />} />
                        )}
                        {giveAccess && currentUser && (
                            <Route
                                path="/meeting-notes"
                                element={<ViewMeetingMinutes />}
                            />
                        )}
                        {giveAccess && currentUser && (
                            <Route
                                path="/meetings-and-tools"
                                element={<ViewMeetingTools />}
                            />
                        )}
                        {giveAccess && currentUser && (
                            <Route
                                path="/myelinners"
                                element={<ViewMyelinners />}
                            />
                        )}
                        {giveAccess && currentUser && (
                            <Route
                                path="/advisors"
                                element={<ViewKnowAdvisors />}
                            />
                        )}
                        <Route
                            path="/training-requirements"
                            element={<ViewTrainingRequirements />}
                        />
                        {/* Master */}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/master/9830088"
                                element={<ViewMasterExportImport />}
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/user-feedbacks"
                                element={<AdminPortalFeedbacks />}
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/blogs"
                                element={<ViewBlogs />}
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/blogs/edit/:id"
                                element={<AdminEditBlog />}
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/blogs/"
                                element={<ViewBlogs />}
                            />
                        )}
                        {giveAccess && isAdmin && (
                            <Route
                                path="/admin/jobs/"
                                element={<AdminJobPortal />}
                        />
                        )}

                        {giveAccess && currentUser && (
                            <Route
                                path="/feedback"
                                element={<ViewFeedback />}
                            />
                        )}
                        {giveAccess && currentUser && (
                            <Route
                                path="/demodemo"
                                element={<ViewDemoDemo />}
                            />
                        )}
                        <Route
                            path="/my-benefits"
                            element={<ViewMyBenefits />}
                        />
                        <Route
                            path="/portal-feedback"
                            element={<ViewPortalFeedback />}
                        />
                        <Route path="/admin/gallery" element={<ViewGallery />} />
                        <Route path="/admin/documents" element={<AdminDocumentManager />} />
                        {/* <Route path="/hr-policies" element={<ViewHRPolicies />} /> */}
                        <Route path="/login" element={<LoginPage />} />
                        {/* <Route path="/claim-management">
                        <ViewDashboard />
                    </Route>
                    <Route path="/leave-management">
                        <ViewMedicalApproval /> 
                    </Route>
                    <Route path="/training-requirements">
                        <ViewMedicalApproval />
                    </Route>
                    <Route path="/my-benefits">
                        <ViewMedicalApproval />
                    </Route>
                    <Route path="/hr-policies">
                        <ViewMedicalApproval />
                    </Route> */}

                        {giveAccess && (
                            <Route
                                path="/"
                                element={
                                    <ViewPersonalProfile email={empEmail} />
                                }
                            />
                        )}
                        <Route path="*" element={<LoginPage />} />
                    </Routes>
                )}
            </Router>
        </>
    );
};

export default Main;
